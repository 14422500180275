
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import {
  IUser,
  IUserCreateRequest,
  IUserRequest,
  IUserUpdateRequest
} from "@/types/user";
import { UserRequest } from "@/models/user";

@Component({})
export default class extends Vue {
  //user
  @Action("user/adminGet")
  public getUser!: (user_id: number) => void;

  @Action("user/adminCreate")
  public create!: (params: IUserCreateRequest) => boolean;

  @Action("user/adminUpdate")
  public update!: (data: {
    user_id: number;
    params: IUserUpdateRequest;
  }) => boolean;

  @Action("user/adminDelete")
  public deleteUser!: (user_id: number) => boolean;

  @Action("user/adminIsUniqueInitialName")
  public isUniqueInitialName!: (params: IUserRequest) => boolean;

  @Action("user/adminIsUniqueEmail")
  public isUniqueEmail!: (params: IUserRequest) => boolean;

  @Getter("user/single")
  public user!: IUser;

  @Mutation("user/clear")
  public clear!: () => void;

  @Watch("disp_password")
  public changeDispPassword() {
    if (!this.disp_password) {
      this.params.password = null;
    }
  }

  //パラメータ定義
  public params: IUserUpdateRequest = new UserRequest();

  //変数定義
  public user_id = 0;
  public submit_dialog = false;
  public destroy_dialog = false;
  public disp_password = false;
  public valid = true;
  public lazy = false;
  public email_errors: string[] = [];
  public initial_name_errors: string[] = [];

  //ルール設定
  public rules = {
    name: [(v: string) => !!v || "名前は必須です"],
    name_eng: [(v: string) => !!v || "名前[Eng]は必須です"],
    kana: [
      (v: string) => !v || /^[ｦ-ﾟ]+$/.test(v) || "半角ｶｶﾀｶﾅで入力してください"
    ],
    initial_name: [(v: string) => !!v || "イニシャルは必須です"],
    email: [
      (v: string) => !!v || "ログイン用メールアドレスは必須です",
      (v: string) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "正しいメールアドレスを入力してください"
    ],
    password: [
      (v: string) => !!v || "パスワードは必須です",
      (v: string) => /^[a-zA-Z0-9]+$/.test(v) || "半角英数字で入力してください",
      (v: string) => /^.{8,}$/.test(v) || "8文字以上を入力してください"
    ]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.user_id = Number(this.$route.params.user_id);
    this.clear();

    // 編集時
    if (this.user_id) {
      await this.getUser(this.user_id);
    }

    this.setDefault();

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private setDefault() {
    this.params.createFromUser(this.user);
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;
    if (this.user_id) {
      result = await this.update({
        user_id: this.user_id,
        params: this.params
      });
    } else {
      result = await this.create(this.params);
    }
    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deleteUser(this.user_id)) {
      this.$router.go(-1);
    }
  }

  // イニシャルの二重登録チェック
  public async initial_name_validate() {
    // 登録時
    if (
      !(await this.isUniqueInitialName(this.params)) &&
      !this.user.initial_name
    ) {
      return (this.initial_name_errors = ["既に使用されています"]);
    }

    // 編集時
    if (
      !(await this.isUniqueInitialName(this.params)) &&
      this.user.initial_name != this.params.initial_name
    ) {
      return (this.initial_name_errors = ["既に使用されています"]);
    }

    return (this.initial_name_errors = []);
  }

  // Emailの二重登録チェック
  public async email_validate() {
    // 登録時
    if (!(await this.isUniqueEmail(this.params)) && !this.user.email) {
      return (this.email_errors = ["既に使用されています"]);
    }

    // 編集時
    if (
      !(await this.isUniqueEmail(this.params)) &&
      this.user.email != this.params.email
    ) {
      return (this.email_errors = ["既に使用されています"]);
    }

    return (this.email_errors = []);
  }
}
